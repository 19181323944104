import React from "react";
import "./../css/GiftCredits.css";

export default function AddApiKey({
  heading,
  message,
  type,
  value,
  setValue,
  onClose,
  onConfirm,
}: {
  heading: string;
  message: string;
  type: string;
  value: string;
  setValue: (value: string) => void;
  onClose: () => void;
  onConfirm: () => void;
}) {
  return (
    <div className="backdrop">
      <div className={`alert alert-${type}`}>
        <div>
          <h2 className="heading">{heading}</h2>
        </div>
        <div>
          <p className="sub-heading">{message}</p>
        </div>
        <div className="credit-input">
          <input
            type="text"
            autoFocus
            value={value}
            className="input-coins"
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) =>
              e.key === "Enter" && value.length > 0 && onConfirm()
            }
          />
        </div>
        <div className="buttons">
          <button className="btt grey" onClick={onClose}>
            Close
          </button>
          <button className="btt right" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

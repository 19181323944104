import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../css/Profile.css";
import RingChart from "./RingChart";
import { motion } from "framer-motion";
import CreditsWarning from "../common/CreditsWarning";
import apiHandler from "../api";
import { endpoint } from "../api/endpoint";
interface credits {
  credits: string;
  credits_used: string;
}

export default function Profile() {
  const user = useSelector((state: any) => state.user.userDetails);
  const [credits, setCredits] = useState<credits>({
    credits: "0",
    credits_used: "0",
  });

  const navigate = useNavigate();

  const handleEditProfile = () => {
    navigate("/auth/profile");
  };

  const getUserCredits = async () => {
    const credits = await apiHandler({
      url: endpoint.GET_CREDITS,
      method: "GET",
    });
    setCredits(credits.data);
  };

  useEffect(() => {
    getUserCredits();
  }, []);

  return (
    <div className="default-padding">
      <div className="profile-title">
        <h1>User Profile</h1>
        <div className="line">
          <hr />
        </div>
      </div>
      <div className="profile-section">
        <motion.div
          initial={{ y: 40, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          className="profile-div"
        >
          <div>
            <h2>{user?.first_name + " " + user?.last_name}</h2>
            <div>
              <h3 className="sub-title">Basic Info</h3>
              <div className="profile-flex">
                <div>
                  <div className="profile-details">
                    <h3>Email</h3>
                    <p>{user?.email}</p>
                  </div>
                  <div className="profile-details">
                    <h3>Designation</h3>
                    <p>{user?.designation}</p>
                  </div>
                  <div className="profile-details">
                    <h3>Affliation</h3>
                    <p>{user?.affiliation}</p>
                  </div>
                  <button className="btt bt-margin" onClick={handleEditProfile}>
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="credits profile-credits">
            <RingChart
              data={{
                title: "Credits",
                count: parseFloat(credits.credits),
                labels: ["Used", "Remaining"],
                colors: ["#FF6384", "#6CCBF2"],
                innerTitle: "Total Credits",
                innerCount: parseFloat(credits.credits_used),
              }}
            />
          </div>
        </motion.div>
      </div>
      <motion.div
        initial={{ y: 40, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        className="credits-warning"
      >
        <CreditsWarning alert={parseFloat(credits.credits) < 25} />
      </motion.div>
    </div>
  );
}

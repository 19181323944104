import axios from "axios";
import { endpoint } from "./endpoint";

interface IApiHandler {
  url: string;
  method: string;
  data?: any; // Make data optional
}

const apiHandler = async ({ url, method, data }: IApiHandler) => {
  console.log(endpoint.BASE_URL + url);
  try {
    let response;

    switch (method.toUpperCase()) {
      case "GET":
        response = await axios.get(endpoint.BASE_URL + url);
        break;
      case "POST":
        response = await axios.post(endpoint.BASE_URL + url, data);
        break;
      case "PUT":
        response = await axios.put(endpoint.BASE_URL + url, data);
        break;
      case "DELETE":
        response = await axios.delete(endpoint.BASE_URL + url);
        break;
      default:
        response = await axios({ method, url: endpoint.BASE_URL + url, data });
    }

    return response;
  } catch (error) {
    console.error("API request failed:", error);
    throw error; // Re-throw the error
  }
};

export default apiHandler;

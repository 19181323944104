export const endpoint = {
  BASE_URL: process.env.REACT_APP_API_DOMAIN,
  USER_PROFILE: "/admin/userProfile/",
  GET_CREDITS: "/admin/getCredits/",
  GIFT_CREDITS: "/super_admin/giftCredits/",
  ADD_API_KEY: "/admin/addAPIKey/",
  GET_API_KEYS: "/admin/getAPIKeys/",
  CREATE_ORDER: "/payment/createOrder/",
  CAPTURE_ORDER: "/payment/captureOrder/",
  GET_USER: "/admin/getUser/",
  GET_PRODUCTS: "/payment/getProducts/",
  GET_ALL_USERS: "/super_admin/getAllUsers/",
  ARCHIVE_API_KEY: "/admin/archiveApiKey/",
  TOGGLE_USER_STATUS: "/super_admin/toggleUserStatus/",
};

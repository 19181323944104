import React from "react";
import "./../css/GiftCredits.css";
import { FaDollarSign } from "react-icons/fa";

export default function GiftCredits({
  heading,
  message,
  type,
  value,
  setValue,
  textValue,
  setTextValue,
  onClose,
  onConfirm,
}: {
  heading: string;
  message: string;
  type: string;
  value: number;
  setValue: (value: number) => void;
  textValue: string;
  setTextValue: (value: string) => void;
  onClose: () => void;
  onConfirm: () => void;
}) {
  return (
    <div className="backdrop">
      <div className={`alert alert-${type}`}>
        <div>
          <h2 className="heading">{heading}</h2>
        </div>
        <div>
          <p className="sub-heading">{message}</p>
        </div>
        <div className="credit-input">
          <span>
            <FaDollarSign className="coin-icon" /> Credits:
          </span>
          <input
            type="number"
            autoFocus
            value={value}
            className="input-coins"
            onChange={(e) => setValue(parseInt(e.target.value, 10))}
            onKeyDown={(e) => e.key === "Enter" && value > 0 && onConfirm()}
          />
        </div>
        <div className="credit-input">
          <input
            type="text"
            placeholder="Description"
            value={textValue}
            className="input-coins"
            onChange={(e) => setTextValue(e.target.value)}
          />
        </div>
        <div className="buttons">
          <button className="btt grey" onClick={onClose}>
            Close
          </button>
          <button className="btt right" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "../css/BuyCredits.css";
import { FaDollarSign } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import CustomBanner from "../common/CustomBanner";
import RazorpayButton from "../components/RazorpayButton";
import apiHandler from "../api";
import { endpoint } from "../api/endpoint";

interface Product {
  id: number;
  name: string;
  price: number;
  currency: string;
  credits: number;
}

export default function BuyCredits() {
  const [products, setProducts] = useState<Product[]>([]);

  const getProducts = async () => {
    try {
      const response = await apiHandler({
        method: "GET",
        url: endpoint.GET_PRODUCTS,
      });
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div className="default-padding">
      <h1 className="heading">Buy Credits</h1>
      <p className="sub-heading">
        Purchase credits to access premium features and services. Choose from
        the options below and clic on <span>Buy Now</span> to proceed.
      </p>
      <div className="card-container">
        {products.map((product) => (
          <div className="card" key={product.id}>
            <h2>{product.name}</h2>
            <p>
              <FaDollarSign className="green" />
              {product.credits} Credits
            </p>
            <p>
              <span>
                Price: {product.price} {product.currency}
              </span>
            </p>
            {product.id === 1 ? (
              <ul>
                <li>
                  <TiTick className="green" />
                  Basic features
                </li>
                <li>
                  <TiTick className="green" />
                  Limited support
                </li>
                <li>
                  <RxCross2 className="red" />
                  No Gift Support
                </li>
              </ul>
            ) : product.id === 2 ? (
              <ul>
                <li>
                  <TiTick className="green" />
                  Advanced features
                </li>
                <li>
                  <TiTick className="green" />
                  Priority support
                </li>
                <li>
                  <TiTick className="green" />
                  Gift Support
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <TiTick className="green" />
                  All features
                </li>
                <li>
                  <TiTick className="green" />
                  24/7 support
                </li>
                <li>
                  <TiTick className="green" />
                  Gift Support
                </li>
              </ul>
            )}
            <RazorpayButton product={product} />
          </div>
        ))}
      </div>
      <CustomBanner
        alert={false}
        message="Need More Credits for organization?"
        messagetag="Contact our support team for more information"
        buttontag="Contact Support"
      />
    </div>
  );
}

import React from "react";
import "./../css/NoDataCard.css";

const NoUserDataCard = ({ width = "auto" }) => {
  return (
    <div>
      <div className="noUserDataCard" style={{ width: width }}>
        <div className="rows-div">
          <div className="circle"></div>
          <div className="block"></div>
          <div className="block"></div>
        </div>
        <div className="rows"></div>
        <div className="rows"></div>
        <div className="rows"></div>
      </div>
    </div>
  );
};

export default NoUserDataCard;

import React, { Fragment } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { Toaster } from "react-hot-toast";

import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";
import CompleteProfile from "./components/CompleteProfile";
import Profile from "./components/Profile";
import UserPage from "./pages/UserPage";
import UserDetails from "./pages/User";
import DashboardGraphss from "./components/DashBoardGraphs";
import BuyCredits from "./pages/BuyCredits";

import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import ThirdParty from 'supertokens-web-js/recipe/thirdparty'
import Passwordless from 'supertokens-web-js/recipe/passwordless';

import Auth from "./auth/Auth";
import SocialAuth from "./auth/SocialAuth";

interface AppInfo {
  appName: string;
  apiDomain: string;
  apiBasePath: string;
}

SuperTokens.init({
  appInfo: {
    apiDomain: process.env.REACT_APP_API_DOMAIN,
    apiBasePath: "/auth",
    appName: "Pragya",
  } as AppInfo,
  recipeList: [Session.init(), ThirdParty.init(), Passwordless.init()],
});

function App() {
  return (
    <Fragment>
      <Router>
        <Routes>
          <Route path="/auth">
            <Route path="login" element={<LoginPage />} />
            <Route path="verify" element={<Auth />} />
            <Route path="callback/google" element={<SocialAuth />} />
            <Route path="callback/github" element={<SocialAuth />} />
            <Route path="profile" element={<CompleteProfile />} />
          </Route>
          <Route path="/" element={<Dashboard />}>
            <Route index element={<DashboardGraphss />} />
            <Route path="profile/:id" element={<Profile />} />
            <Route path="users" element={<UserPage />} />
            <Route path="users/:id" element={<UserDetails />} />
            <Route path="buycredits" element={<BuyCredits />} />
          </Route>
          <Route path="*" element={<div>Not Found</div>} />
        </Routes>
        <Toaster />
      </Router>
    </Fragment>
  );
}

export default App;

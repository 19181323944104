import React from "react";
import RingChart from "../components/RingChart";
import UserDetails from "../components/UserDetails";
import "./../css/User.css";
import BarChart from "../components/Barchart";
import apiHandler from "../api";
import { endpoint } from "../api/endpoint";
import { useEffect, useState } from "react";

const User = () => {
  const Bardata = {
    title: "Api's Requests",
    labels: [
      "endpoint1",
      "endpoint2",
      "endpoint3",
      "endpoint4",
      "endpoint5",
      "endpoint6",
      "endpoint7",
      "endpoint8",
    ],
    colors: ["#6CCBF2"],
    count: [200, 300, 400, 500, 600, 700, 800, 900],
    legendtitle: "Requests",
  };
  interface Credits {
    credits: string;
    credits_used: string;
  }
  const [credits, setCredits] = useState<Credits>({
    credits: "0",
    credits_used: "0",
  });
  const getUserCredits = async () => {
    const credits = await apiHandler({
      url: endpoint.GET_CREDITS,
      method: "GET",
    });
    setCredits(credits.data);
  };
  const data = {
    title: "User Status",
    count: parseFloat(credits.credits),
    labels: ["Active", "Inactive"],
    colors: ["#E028AD", "#6CCBF2"],
    innerTitle: "Total",
    innerCount: 0,
  };
  useEffect(() => {
    getUserCredits();
  }, []);
  return (
    <div className="searchPage default-padding ">
      <div className="userDetailsCard">
        <UserDetails />
        <div className="user-chats">
          <RingChart data={data} width="450px" height="400px" />
          <BarChart data={Bardata} width={450} height={400} />
        </div>
      </div>
    </div>
  );
};

export default User;

import React from "react";
import "./../css/NoDataMessage.css";
interface NotDataMessageProps {
    width?: string;
    message: string;
  }
  
  const NotDataMessage: React.FC<NotDataMessageProps> = ({ width = "auto", message }) => {

  return (
    <div style={{ width: width }} className="nodatamessage">
      <p>{message}</p>
    </div>
  );
};

export default NotDataMessage;
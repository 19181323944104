import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "../css/RingChart.css";
import { motion } from "framer-motion";
// import { useGlobalContext } from "./GlobalContext";
// import NoGraphCard from "../common/NoGraphCard";

interface RingChartProps {
  data: {
    title: string;
    count: number;
    labels: string[];
    colors: string[];
    innerTitle: string;
    innerCount: number;
  };
  width?: string;
  height?: string;
}

const RingChart: React.FC<RingChartProps> = ({
  data,
  width = "auto",
  height = "auto",
}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart<"doughnut"> | null>(null);
  //   const { noUser, setNoUser } = useGlobalContext();

  const { title, count, labels, colors, innerTitle, innerCount } = data;

  useEffect(() => {
    const ctx = chartRef.current?.getContext("2d");

    if (!ctx) return;

    // Destroy existing chart instance before creating a new one
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    chartInstance.current = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: labels,
        datasets: [
          {
            data: [innerCount, count], // Assuming data is a percentage, use the complementary value
            backgroundColor: colors,
            hoverOffset: 4,
          },
        ],
      },
      options: {
        cutout: "90%", // Adjust the cutout percentage to create the ring effect

        plugins: {
          legend: {
            position: "bottom",
            align: "center",
            labels: {
              usePointStyle: true,
              boxWidth: 10,
              boxHeight: 10,
              padding: 20,
              font: {
                size: 14,
              },
              pointStyle: "circle",
            },
          },
          title: {
            display: true,
            text: title,
            font: {
              size: 20,
            },
          },
        },
      },
    });

    // Clean up the chart instance when the component is unmounted
    return () => {
      //   setNoUser(false);
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [count, labels, colors, title, innerTitle, innerCount]);

  return (
    <motion.div
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: 40, opacity: 0 }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="outer"
      style={{
        width: width,
        height: height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {/* {noUser ? <NoGraphCard width="400px" /> : null} */}
      <div>
        <div>
          <canvas ref={chartRef}></canvas>
        </div>
        <div className="inner">
          <h3>{innerTitle}</h3>
          <h1>
            {
              //(!isNaN(Number(innerCount)) && !isNaN(Number(count)))
              //? (Number(innerCount) + Number(count)).toLocaleString()
              //: 'Invalid data'
              (innerCount + count).toLocaleString()
            }
          </h1>
        </div>
      </div>
    </motion.div>
  );
};

export default RingChart;

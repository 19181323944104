import React, { useEffect, useState } from "react";
import "./../components/Input";
import Input from "./Input";
import "./../css/UserDetails.css";
import Switch from "./Switch";
import { getFormattedDay } from "../common/getFormattedDay";
import Coin from "./Coin";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import NoUserDataCard from "../common/NoUserDataCard";
import { useSelector } from "react-redux";
import apiHandler from "../api";
import { endpoint } from "../api/endpoint";
import AddApiKey from "./AddApiKey";
import { toast } from "react-hot-toast";
interface UserData {
  id: string;
  active: boolean;
  email: string;
  credits: string;
  supertokens_userid: string;
  first_name: string;
  last_name: string;
  designation: string;
  affiliation: string;
  is_active: boolean;
  credits_used: string;
  is_superuser: boolean;
  created_at: string;
}

interface ApiKeyProps {
  name: string;
  key: string;
  active: boolean;
  id: string;
}

const UserDetails = () => {
  const [userData, setUserData] = useState<UserData>({
    email: "",
    active: false,
    credits: "0",
    id: "",
    supertokens_userid: "",
    first_name: "",
    last_name: "",
    designation: "",
    affiliation: "",
    is_active: false,
    credits_used: "0",
    is_superuser: false,
    created_at: "",
  });

  const [apiKeys, setApiKeys] = useState<ApiKeyProps[]>([]);
  const { id } = useParams<{ id: string }>();
  const [apiKeyName, setApiKeyName] = useState<string>("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [userActiveStatus, setUserActiveStatus] = useState<boolean>(
    userData.active
  );
  const user = useSelector((state: any) => state.user.userDetails);

  const AddApiKeyFun = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleUserActiveStatus = async () => {
    try {
      const response = await apiHandler({
        method: "PUT",
        url: endpoint.TOGGLE_USER_STATUS,
        data: {
          user_id: userData.id,
        },
      });

      if (response.status === 200) {
        setUserActiveStatus(response.data.is_active);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUserData = async () => {
    try {
      const response = await apiHandler({
        method: "GET",
        url: endpoint.GET_USER + id + "/",
      });

      if (response.data) {
        setUserData(response.data);
        setUserActiveStatus(response.data.is_active);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getApiKey = async () => {
    try {
      const response = await apiHandler({
        method: "GET",
        url: endpoint.GET_API_KEYS,
      });

      if (response.data) {
        setApiKeys(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const addApiKey = async () => {
    console.log(apiKeyName);
    if (apiKeyName.trim() !== "") {
      try {
        const response = await apiHandler({
          method: "POST",
          url: endpoint.ADD_API_KEY,
          data: { name: apiKeyName },
        });
        console.log(apiKeyName);
        if (response.data) {
          console.log(response.data);
          setApiKeys([...apiKeys, response.data]);
          toast.success("Api Key Added");
          setPopupOpen(false);
        } else {
          toast.error("Api Key Not Added");
          setPopupOpen(false);
        }
      } catch (e) {
        toast.error("Api Key Not Added");
        setPopupOpen(false);
        console.log(e);
      }
    }
  };

  useEffect(() => {
    getUserData();
  }, [id]);

  useEffect(() => {
    getApiKey();
  }, []);

  return (
    <motion.div
      animate={{ x: 0, opacity: 1 }}
      initial={{ x: -100, opacity: 0 }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="UserDetails"
    >
      {userData.email ? (
        <>
          <div className="profile-section">
            <div className="profile-div">
              <div>
                <h2>{userData?.first_name + " " + userData?.last_name}</h2>
                <p>
                  Joined on : {getFormattedDay(new Date(userData.created_at))}
                </p>
                <div>
                  <h3 className="sub-title">Basic Info</h3>
                  <div className="profile-flex">
                    <div>
                      <div className="profile-details">
                        <h3>Email</h3>
                        <p>{userData?.email}</p>
                      </div>
                      <div className="profile-details">
                        <h3>Designation</h3>
                        <p>{userData?.designation}</p>
                      </div>
                      <div className="profile-details">
                        <h3>Affliation</h3>
                        <p>{userData?.affiliation}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-right">
                {user.is_superuser && (
                  <Switch
                    handleFuc={handleUserActiveStatus}
                    key={id}
                    activeStatus={userActiveStatus}
                  />
                )}
                <Coin
                  coins={userData.credits}
                  size="10px"
                  userId={userData.id}
                  giftOption={user.is_superuser}
                />
              </div>
            </div>
          </div>
          <div className="userApiKeys">
            {user.id === userData.id && (
              <>
                <h2>Api Keys</h2>
                <div className="inputs">
                  {apiKeys &&
                    apiKeys.map(
                      (key, index) =>
                        key.active && (
                          <Input
                            key={index}
                            index={index}
                            label={key.name}
                            id="apikey"
                            data={key}
                            getApiKey={getApiKey}
                          />
                        )
                    )}
                </div>
                <div className="add-api-btn">
                  <button onClick={AddApiKeyFun} className="btt">
                    Create New API Key
                  </button>
                  {isPopupOpen && (
                    <AddApiKey
                      heading="Add API Key"
                      message="Enter the name of the API Key"
                      type="success"
                      value={apiKeyName}
                      setValue={setApiKeyName}
                      onClose={closePopup}
                      onConfirm={addApiKey}
                    />
                  )}
                </div>
                <h2>Archived Api Keys</h2>
                <div className="inputs archived">
                  {apiKeys &&
                    apiKeys.map(
                      (key, index) =>
                        !key.active && (
                          <Input
                            key={index}
                            index={index}
                            label={key.name}
                            id="apikey"
                            data={key}
                          />
                        )
                    )}
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <NoUserDataCard />
      )}
    </motion.div>
  );
};

export default UserDetails;

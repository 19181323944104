import { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import "./../css/SearchBar.css";
import { motion } from "framer-motion";
import React from "react";
interface SearchBarProps {
    className: string;
  }
  
  const SearchBar: React.FC<SearchBarProps> = ({ className }) => {
  const [searchText, setSearchText] = useState("");

  function handleSearchText(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
  }

  return (
    <motion.div
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: 40, opacity: 0 }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className={`searchBarDiv ${className}`}
    >
      <input
        className="searchBar"
        placeholder="Search Users..."
        value={searchText}
        onChange={handleSearchText}
      />
      <IoIosSearch className="search-icon" />
    </motion.div>
  );
};

export default SearchBar;
import { useState } from "react";
import { FcInfo } from "react-icons/fc";
import "./../css/Input.css";
import { motion } from "framer-motion";
import { getFormattedDateWithTime } from "../common/getFormattedDay";
import { MdContentCopy } from "react-icons/md";
import { IoArchive } from "react-icons/io5";
import React from "react";
import toast from "react-hot-toast";
import CustomAlert from "../common/CustomAlert";
import apiHandler from "../api";
import { endpoint } from "../api/endpoint";
import { useSelector } from "react-redux";
interface InputProps {
  id: string;
  label: string;
  data: any; // replace any with the actual type of data
  index: number;
  getApiKey?: () => void;
}
const Input: React.FC<InputProps> = ({ id, label, data, index, getApiKey }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showArchiveAlert, setShowArchiveAlert] = useState(false);
  const user = useSelector((state: any) => state.user.userDetails);

  function handleTooltipOpen() {
    setTooltipOpen((state) => !state);
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(data.key);
    toast.success("copied to clipboard");
  };

  const handleArchive = () => {
    setShowArchiveAlert(true);
  };

  const handleArchiveConfirm = async () => {
    try {
      const response = await apiHandler({
        method: "PUT",
        url: endpoint.ARCHIVE_API_KEY,
        data: {
          apikey_id: data.id,
        },
      });
      if (response.status === 200) {
        toast.success("Key archived successfully");
        setShowArchiveAlert(false);
        getApiKey && getApiKey();
      } else {
        toast.error("Failed to archive key");
        setShowArchiveAlert(false);
      }
    } catch (err) {
      toast.error("Failed to archive key");
      setShowArchiveAlert(false);
    }
  };

  return (
    <motion.div
      initial={{ y: -10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.2, delay: index * 0.03 }}
      className="inputDiv"
    >
      <label htmlFor={id}>{label}:</label>
      <div className="input">
        <div className="placeholder">{"*".repeat(data.key.length)}</div>
        <div className="icons">
          <div className="icon-1">
            <MdContentCopy onClick={handleCopy} className="input-icons" />
          </div>
          {data.active && (
            <div className="icon-3">
              <IoArchive className="input-icons" onClick={handleArchive} />
            </div>
          )}
          <div>
            <span
              className="tooltip"
              style={tooltipOpen ? { opacity: "1" } : { opacity: "0" }}
            >
              {getFormattedDateWithTime(new Date(data.created_at))}
            </span>
            <FcInfo
              onMouseEnter={handleTooltipOpen}
              onMouseLeave={handleTooltipOpen}
              className="input-icons icon-1"
            />
          </div>
        </div>
        {showArchiveAlert && (
          <CustomAlert
            heading={`Archive Key:  ${label}`}
            message="Are you sure you want to archive this key?"
            type="danger"
            onClose={() => setShowArchiveAlert(false)}
            onConfirm={handleArchiveConfirm}
          />
        )}
      </div>
    </motion.div>
  );
};

export default Input;

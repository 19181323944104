import React from "react";
import "../css/CreditsWarning.css";
import { useNavigate } from "react-router-dom";

export default function CreditsWarning({ alert }: { alert: boolean }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/buycredits");
  };

  return (
    <>
      {alert ? (
        <div className="warning red">
          <div>
            <h1>You are running low on credits</h1>
            <p>Select booster plan and buy</p>
          </div>
          <div>
            <button onClick={handleClick} className="btt">
              Buy Credits
            </button>
          </div>
        </div>
      ) : (
        <div className="warning green">
          <div>
            <h1>You have enough credits</h1>
            <p>Want to buy more credits? Select a booster and buy.</p>
          </div>
          <div>
            <button onClick={handleClick} className="btt">
              Buy Credits
            </button>
          </div>
        </div>
      )}
    </>
  );
}

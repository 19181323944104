const months: string[] = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getFormattedDay = (date: Date): string => {
  const month: string = months[date.getMonth()];
  const day: number = date.getDate();
  const year: number = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

export const getFormattedDateWithTime = (date: Date): string => {
  const month: string = months[date.getMonth()];
  const day: number = date.getDate();
  const year: number = date.getFullYear();
  const hours: number = date.getHours();
  const minutes: number = date.getMinutes();
  const seconds: number = date.getSeconds();
  const paddedHours: string = hours < 10 ? `0${hours}` : hours.toString();
  const paddedMinutes: string = minutes < 10 ? `0${minutes}` : minutes.toString();
  const paddedSeconds: string = seconds < 10 ? `0${seconds}` : seconds.toString();
  return `${month} ${day}, ${year} ${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};



import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import AvatarPng from "../images/avatar.png";
import { useSelector } from "react-redux";
import VerificationLoader from "../common/VerificationLoader";
import apiHandler from "../api";
interface Product {
  id: number;
  name: string;
  price: number;
  currency: string;
  credits: number;
}

interface RazorpayButtonProps {
  product: Product;
}

const RazorpayButton: React.FC<RazorpayButtonProps> = ({ product }) => {
  const user = useSelector((state: any) => state.user.userDetails);
  const userEmail = user?.email;
  const userName = user?.first_name + " " + user?.last_name; // or any other field required

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadScript = async () => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);
    };

    loadScript();
  }, []);

  const handleClick = async (product: any) => {
    try {
      const order_response = await apiHandler({
        method: "POST",
        url: "/payment/createOrder/",
        data: {
          user_id: user.id,
          credits_purchased: product.credits,
          amount_of_order: product.price,
          currency: product.currency,
          payment_mode: "razorpay",
        },
      });

      const { order_id, razorpay_id } = order_response.data;
      console.log("chargin...", Math.round(product.price * 100));

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: String(Math.round(product.price * 100)),
        currency: product.currency,
        name: "Pragya",
        description: "Plan: " + product.name,
        image: AvatarPng,
        order_id: razorpay_id,
        handler: async function (response: any) {
          try {
            setLoading(true);
            console.log(response);
            const response1 = await apiHandler({
              method: "POST",
              url: "/payment/captureOrder/",
              data: {
                order_id: order_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              },
            });

            // alert(response1.data.status);
            if (response1.data.status === "fulfilled") {
              toast.success("Payment Successful");
              setLoading(false);
            } else {
              toast.error("Payment Failed");
              setLoading(false);
            }
          } catch (e) {
            console.log(e);
            toast.error("Payment Failed");
            setLoading(false);
          }
        },
        prefill: {
          name: userName,
          email: userEmail,
        },
      };

      if (scriptLoaded) {
        console.log(options);
        const rzp = new (window as any).Razorpay(options);
        rzp.open();
      }
    } catch (error) {
      console.error(error);
      toast.error("Payment Failed");
      setLoading(false);
    }
  };

  return (
    <div>
      <button
        id={`rzp-button1-${product.id}`}
        className="btt"
        onClick={() => handleClick(product)}
        disabled={loading} // Disable button while loading
      >
        {loading ? "Processing..." : "Buy Now"}
      </button>
      {loading ? (
        <VerificationLoader
          title="Processing Payment"
          tagline="Please wait while we process your payment"
        />
      ) : null}
    </div>
  );
};

export default RazorpayButton;

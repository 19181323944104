import "./../css/LineChart.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useState } from "react";
import { Line } from "react-chartjs-2";
import { motion } from "framer-motion";
import React from 'react';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    legend: { display: false,},
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      border: {
        color: "#e028ac",
        width: 2,
      },
      ticks: {
        color: "black",
        font: {
          size: 16,
          weight: "bold"as const, // ensure weight is one of the specific string values
        },
      
    },
    },
    y: {
      grid: {
        display: false,
      },

      border: {
        color: "#e028ac",
        width: 2,
      },
    },
  },
  pointBackgroundColor: "#e028ac",
  pointBorderColor: "#e028ac",
};

const sortData = {
  date: ["00:00", "01:00", "23:59"],
  week: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  year: [2023, 2024, 2025],
  month: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
};
const sortOrders = ["Date", "Week", "Month", "Year"];

interface LineChartProps {
  name: string;
  width?: string;
  height?: string;
}

const LineChart: React.FC<LineChartProps> = ({ name, width = "551px", height = "374px" }) => {
  const [endPoint, setEndPoint] = useState(1);
  const [sortOrder, setSortOrder] = useState("month");

  function handleEndPoint(event: React.ChangeEvent<HTMLSelectElement>) {
    setEndPoint(Number(event.target.value));
  }

  function handleSortOrder(order: string) {
    setSortOrder(order.toLowerCase());
  }

  const endPointData = [
    [5, 3, 5, 6, 2, 3, 2, 5, 6, 3, 4, 9],
    [3, 2, 12, 4, 45, 3, 5, 5, 6, 3, 4, 9],
    [2, 4, 4, 2, 2, 4, 5, 5, 6, 3, 4, 9],
    [23, 4, 2, 2, 5, 5, 4, 5, 6, 3, 4, 9],
    [5, 3, 5, 6, 2, 3, 2, 5, 6, 3, 4, 9],
    [3, 2, 12, 4, 45, 3, 5, 5, 6, 3, 4, 9],
    [2, 4, 4, 2, 2, 4, 5, 5, 6, 3, 4, 9],
    [2, 3, 5, 5, 2, 6, 7, 5, 6, 3, 4, 9],
  ];

  const sortData: { [key: string]: string[] | number[] } = {
    date: ["00:00", "01:00", "23:59"],
    week: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    year: [2023, 2024, 2025],
    month: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  };

  const data = {
    labels: (sortData[sortOrder] as (string | number)[]).map(String),
    datasets: [
      {
        label: "Api Requests",
        data: endPointData.at(endPoint),
        tension: 0.5,
        borderWidth: 3,
      },
    ],
  };

  return (
    <motion.div
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: 40, opacity: 0 }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="linechartdiv"
      style={{ width, height }}
    >
      <div className="linechartheader">
        <h1>{name}</h1>
        <ul className="linechartcat">
          {sortOrders.map((order, i) => (
            <li
              onClick={() => handleSortOrder(order)}
              className={
                order.toLocaleLowerCase() === sortOrder ? "active" : ""
              }
              key={i}
            >
              {order}
            </li>
          ))}
        </ul>
        <div>
          <select
            className="options"
            value={endPoint}
            onChange={handleEndPoint}
          >
            {endPointData.map((endpoint, i) => (
              <option key={i} value={i}>
                endpoint {i + 1}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Line data={data} options={options} />
    </motion.div>
  );
};

export default LineChart;


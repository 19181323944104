import React, { useEffect } from "react";
import { signInAndUp } from "supertokens-web-js/recipe/thirdparty";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import VerificationLoader from "../common/VerificationLoader";
import { saveUserDetails } from "../store/actions/userActions";
import { useDispatch } from "react-redux";

const SocialAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function handleGithubCallback() {
    try {
      const response = await signInAndUp();

      if (response.status === "OK") {
        dispatch(
          saveUserDetails({
            supertokens_userid: response.user.id,
            email: response.user.emails[0],
          })
        );

        if (
          response.createdNewRecipeUser &&
          response.user.loginMethods.length === 1
        ) {
          // sign up successful
          toast.success("sign up successful");
          navigate("/auth/profile");
        } else {
          // sign in successful
          toast.success("sign in successful");
          navigate("/");
        }
      } else if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
        // this can happen due to automatic account linking. Please see our account linking docs
        toast.error("Sign in not allowed.");
      } else {
        // SuperTokens requires that the third party provider
        // gives an email for the user. If that's not the case, sign up / in
        // will fail.

        // As a hack to solve this, you can override the backend functions to create a fake email for the user.
        toast.error(
          "No email provided by social login. Please use another form of login"
        );
        navigate("/");
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  useEffect(() => {
    handleGithubCallback();
  }, []);

  return (
    <div>
      <VerificationLoader
        title={"Hold On!"}
        tagline={"You are verifying and redirecting to the dashboard."}
      />
    </div>
  );
};

export default SocialAuth;

import React, { useEffect, useState } from "react";
import "../css/CompleteProfile.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import Session from "supertokens-web-js/recipe/session";
import { useSelector, useDispatch } from "react-redux";
import { saveUserDetails } from "../store/actions/userActions";
import { IoIosArrowRoundBack } from "react-icons/io";
import { motion } from "framer-motion";
import apiHandler from "../api";
import { endpoint } from "../api/endpoint";

export default function CompleteProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.userDetails);

  // Initialize state with user's existing data
  const [formData, setFormData] = useState({
    id: user?.id || 1,
    supertokens_userid: user?.supertokens_userid,
    email: user?.email,
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    designation: user?.designation || "",
    affiliation: user?.affiliation || "",
  });

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const response = (await apiHandler({
        url: endpoint.USER_PROFILE,
        method: "POST",
        data: formData,
      })) as any;
      if (response.status === 200) {
        dispatch(saveUserDetails(response.data));
        toast.success("Profile updated successfully");
        navigate("/");
      } else {
        toast.error("Already Exists account with this email");
        navigate("/auth/login");
      }
    } catch (err) {
      toast.error("Already Exists account with this email");
      navigate("/auth/login");
    }
  };

  const doesSessionExist = async () => {
    if (!(await Session.doesSessionExist())) {
      navigate("/");
    }
  };

  useEffect(() => {
    doesSessionExist();
  }, []);

  return (
    <motion.div
      initial={{ y: 40, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="center c-page"
    >
      <div className="title-flex">
        {user?.first_name && (
          <div className="back-div">
            <IoIosArrowRoundBack
              className="back"
              onClick={() => navigate(`/profile/${user?.id}`)}
            />
          </div>
        )}
        <h1>Welcome to Pragya</h1>
      </div>
      <h3>
        We're excited to have you here. To get started, please take a moment to
        complete your profile.
      </h3>
      <form className="c-form padd" onSubmit={handleSubmit}>
        <div className="fullname">
          <input
            type="text"
            name="first_name"
            placeholder="First Name"
            value={formData.first_name} // Set value from state
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            value={formData.last_name} // Set value from state
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <input
            type="text"
            name="designation"
            placeholder="Designation"
            value={formData.designation} // Set value from state
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <input
            type="text"
            name="affiliation"
            placeholder="Affiliation"
            value={formData.affiliation} // Set value from state
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="btt">
          Update
        </button>
      </form>
    </motion.div>
  );
}

import React, { useState, useEffect } from "react";
import { FaDollarSign } from "react-icons/fa";
import "./../css/UserDetails.css";
import toast from "react-hot-toast";
import GiftCredits from "./GiftCredits";
import apiHandler from "../api";
import Modal from "./Modal";
import "./../css/OfflineModal.css";
interface CoinProps {
  coins: string;
  size: string;
  userId: string;
  giftOption?: boolean;
}

const Coin: React.FC<CoinProps> = ({
  coins: initialCoins,
  size,
  userId,
  giftOption = false,
}) => {
  const [coins, setCoins] = useState<number>(
    initialCoins ? Number(initialCoins) : 0
  );
  const [coinInput, setCoinInput] = useState<number>(0);
  const [showGiftModal, setShowGiftModal] = useState<boolean>(false);
  const [showGiftCredits, setShowGiftCredits] = useState<boolean>(false);
  const [showOfflineTransactionModal, setShowOfflineTransactionModal] =
    useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<string>("");
  const [checkDetails, setCheckDetails] = useState<string>("");
  const [bankname, setBankName] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [transactionID, settransactionID] = useState<number>();
  const [giftTextValue, setGiftTextValue] = useState("");
  const [accountDetails, setAccountDetails] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);

  useEffect(() => {
    setCoins(Number(initialCoins));
  }, [initialCoins]);

  const handleGift = async () => {
    try {
      if (coinInput <= 0) {
        toast.error("Please enter a valid number of coins to gift!");
        return;
      }

      // Create Order
      const orderResponse = await apiHandler({
        method: "POST",
        url: "/payment/createOrder/",
        data: {
          user_id: userId,
          credits_purchased: coinInput,
          amount_of_order: 0,
          currency: "USD",
          payment_mode: "gift",
        },
      });

      // Check if order creation is successful
      if (orderResponse.data) {
        const orderId = orderResponse.data.order_id;

        // Gift Credits
        const response = await apiHandler({
          method: "POST",
          url: "/private_payment/giftCredits/",
          data: {
            order_id: orderId,
            description: giftTextValue,
          },
        });

        // Check if gifting credits is successful
        if (response.data) {
          const updatedCoins = response.data.credits;
          setCoins(updatedCoins);
          toast(`Gifted ${coinInput} coins!`);
          setCoinInput(0);
          setShowGiftCredits(false);
          setShowGiftModal(false);
          setGiftTextValue("");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOfflineTransaction = () => {
    setShowOfflineTransactionModal(true);
  };
  const handleOfflineTransactionConfirmation = async () => {
    try {
      let paymentMode = "";
      let description = {};

      if (transactionType === "check") {
        paymentMode = "Check";
        description = {
          check_no: checkDetails,
          BankName: bankname,
          Date: date,
        };
      } else if (transactionType === "bank_transfer") {
        paymentMode = "Bank Transfer";
        description = {
          account_details: accountDetails,
          Transaction_ID: transactionID,
          Date: date,
        };
      } else if (transactionType === "cash") {
        paymentMode = "Cash";
        description = { amount: amount.toString() };
      }

      //  Create Order
      const orderResponse = await apiHandler({
        method: "POST",
        url: "/payment/createOrder/",
        data: {
          user_id: userId,
          credits_purchased: coinInput,
          amount_of_order: amount,
          currency: "USD",
          payment_mode: "offline",
        },
      });

      if (orderResponse.data) {
        const orderId = orderResponse.data.order_id;

        // Fulfill Offline Transaction
        const fulfillResponse = await apiHandler({
          method: "POST",
          url: "/private_payment/fulfillOfflineTransaction/",
          data: {
            order_id: orderId,
            payment_mode: paymentMode,
            description: description,
          },
        });

        console.log(fulfillResponse);

        setShowOfflineTransactionModal(false);
      }
    } catch (error) {
      console.error("Error handling offline transaction:", error);
    }
  };

  const handleGiftModalClose = () => {
    setShowGiftModal(false);
  };

  return (
    <div style={{ fontSize: size }}>
      <h3 className="coins">
        <span>
          <FaDollarSign className="coin-icon" />
          {coins && Number(coins).toFixed(2).toLocaleString()}
        </span>
        {giftOption && (
          <span
            className="addButton gift-button-icon"
            onClick={() => setShowGiftModal(true)}
          >
            +
          </span>
        )}
      </h3>

      {showGiftModal && (
        <Modal show={showGiftModal} onClose={handleGiftModalClose}>
          <div className="modal-content">
            <h2 style={{ marginBottom: 15 }}>Choose Payment Mode</h2>
            <button
              className="text-input"
              onClick={() => {
                setShowGiftCredits(true);
                setShowGiftModal(false);
              }}
            >
              Gift Transaction
            </button>
            <button
              className="text-input"
              onClick={() => {
                handleOfflineTransaction();
                setShowGiftModal(false);
              }}
            >
              Offline Transaction
            </button>
          </div>
        </Modal>
      )}

      {showGiftCredits && (
        <GiftCredits
          heading="Gift Credits"
          message="Enter the number of credits you want to gift"
          type="success"
          value={coinInput}
          setValue={setCoinInput}
          textValue={giftTextValue}
          setTextValue={setGiftTextValue}
          onClose={() => setShowGiftCredits(false)}
          onConfirm={handleGift}
        />
      )}

      {showOfflineTransactionModal && (
        <Modal
          show={showOfflineTransactionModal}
          onClose={() => setShowOfflineTransactionModal(false)}
        >
          <div className="modal-content">
            <h2>Choose Offline Transaction Type</h2>

            <select
              value={transactionType}
              onChange={(e) => setTransactionType(e.target.value)}
              required
              className="select-input"
              style={{ marginTop: 15 }}
            >
              <option value="">Select Transaction Type</option>
              <option value="check">Check</option>
              <option value="cash">Cash</option>
              <option value="bank_transfer">Bank Transfer</option>
            </select>
            {/* Render different inputs based on the transaction type */}
            {transactionType === "check" && (
              <div>
                <input
                  type="text"
                  placeholder="Enter Check Details"
                  value={checkDetails}
                  onChange={(e) => setCheckDetails(e.target.value)}
                  required
                  className="text-input"
                />
                <input
                  type="text"
                  placeholder="Enter Bank Name"
                  value={bankname}
                  onChange={(e) => setBankName(e.target.value)}
                  required
                  className="text-input"
                />
                <label htmlFor="transaction-date">
                  Enter date of transation
                </label>
                <input
                  type="date"
                  id="transaction-date"
                  placeholder="Enter Date of Transaction"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                  className="text-input"
                />
              </div>
            )}

            {transactionType === "bank_transfer" && (
              <div>
                <input
                  type="text"
                  placeholder="Enter Account Details"
                  value={accountDetails}
                  onChange={(e) => setAccountDetails(e.target.value)}
                  required
                  className="text-input"
                />
                <input
                  type="Number"
                  placeholder="Enter Transaction ID"
                  value={transactionID}
                  onChange={(e) => settransactionID(Number(e.target.value))}
                  required
                  className="text-input"
                />
                <label htmlFor="transaction-date">
                  Enter Date of Transaction
                </label>
                <input
                  type="date"
                  id="transaction-date"
                  placeholder="Enter Date of Transaction"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                  className="text-input"
                />
              </div>
            )}

            <div>
              <input
                type="number"
                placeholder="Enter Credits"
                value={coinInput === 0 ? "" : coinInput}
                onChange={(e) => setCoinInput(Number(e.target.value))}
                required
                className="number-input"
              />
            </div>

            <div>
              <input
                type="number"
                placeholder="Enter Amount"
                value={amount === 0 ? "" : amount}
                onChange={(e) => setAmount(Number(e.target.value))}
                required
                className="number-input" // Example CSS class for number input
              />
            </div>
            <button
              className="Confirm-btn"
              onClick={handleOfflineTransactionConfirmation}
            >
              Confirm
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Coin;

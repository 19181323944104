const initialState = {
  userDetails: {},
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SAVE_USER_DETAILS":
      return {
        ...state,
        userDetails: action.payload,
      };
    case "RESET_USER_DETAILS":
      return {
        ...state,
        userDetails: {},
      };
    default:
      return state;
  }
};

export default userReducer;

import React, { useState } from "react";
import { IoPersonCircle } from "react-icons/io5";
import "./../css/userbar.css";
import Switch from "../components/Switch";
import { useNavigate } from "react-router-dom";
import Coin from "../components/Coin";
import { FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion";

interface UserData {
  id: string;
  email: string;
  credits: string;
  is_active: boolean;
  supertokens_userid: string;
}

interface UserBarProps {
  data: UserData;
  index: number;
}

const UserBar: React.FC<UserBarProps> = ({ data, index }) => {
  const navigate = useNavigate();
  const { id, email, credits, supertokens_userid, is_active } = data;
  const [active, setActive] = useState(is_active);
  const handleSwitch = () => {
    setActive(!active);
  };

  const handleUser = (id: string) => {
    navigate(`${id}`);
  };

  return (
    <motion.div
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -50, opacity: 0 }}
      transition={{ duration: 0.2, delay: index * 0.01 }}
      className="all"
      onClick={() => {
        handleUser(supertokens_userid);
      }}
    >
      <div className="flex">
        <div className="icon-user">
          <IoPersonCircle />
        </div>
        <p className="gmail">{email}</p>
      </div>
      <div className="userbar-switch">
        <Switch key={index} activeStatus={active} handleFuc={handleSwitch} />
      </div>
      <div className="userbar-coin">
        <Coin coins={credits} size="10px" userId={id} />
      </div>
      <div className="userbar-arrow">
        <FaArrowRight />
      </div>
    </motion.div>
  );
};

export default UserBar;

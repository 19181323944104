// import React from "react";
// import "../css/Modal.css";

// interface ModalProps {
//   show: boolean;
//   onClose: () => void;
//   handlePaymentModeSelection: (mode: string) => void;
// }

// const Modal: React.FC<ModalProps> = ({
//   show,
//   onClose,
//   handlePaymentModeSelection,
// }) => {
//   if (!show) {
//     return null;
//   }

//   return (
//     <div className="modal-overlay">
//       <div className="modal-content">
//         <h2>Choose Payment Mode</h2>
//         <button onClick={() => handlePaymentModeSelection("online")}>
//           Online Transaction
//         </button>
//         <button onClick={() => handlePaymentModeSelection("offline")}>
//           Offline Payment
//         </button>
//         <button onClick={onClose}>Close</button>
//       </div>
//     </div>
//   );
// };

// export default Modal;

import React from "react";
import "./../css/Modal.css";

interface ModalProps {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ show, onClose, children }) => {
  const modalClasses = show ? "modal-overlay blur-background" : "modal-overlay";

  return (
    <div className={modalClasses}>
      <div className="modal-container">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;

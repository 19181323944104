import React from "react";
import "./../css/GiftCredits.css";

export default function CustomAlert({
  heading,
  message,
  type,
  onClose,
  onConfirm,
}: {
  heading: string;
  message: string;
  type: string;
  onClose: () => void;
  onConfirm: () => void;
}) {
  return (
    <div className="backdrop">
      <div className={`alert alert-${type}`}>
        <div>
          <h2 className="heading">{heading}</h2>
        </div>
        <div>
          <p className="sub-heading">{message}</p>
        </div>
        <div className="buttons">
          <button className="btt grey" onClick={onClose}>
            Close
          </button>
          <button className="btt right" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import SideNavBar from "../components/SideNavBar";
import { Outlet } from "react-router-dom";
import Session from "supertokens-web-js/recipe/session";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { saveUserDetails } from "../store/actions/userActions";
import apiHandler from "../api";
import { endpoint } from "../api/endpoint";
import { motion } from "framer-motion";

import LineChart from "../components/LineChart";
import "./../css/Dashboard.css";
import RingChart from "../components/RingChart";
const DashboardGraphs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.userDetails);

  async function doesSessionExistS() {
    if (!(await Session.doesSessionExist())) {
      navigate("/auth/login");
    }
  }

  async function getUserDeatils() {
    const user = await apiHandler({
      url: endpoint.USER_PROFILE,
      method: "GET",
    });
    dispatch(saveUserDetails(user.data));
    if (user.data?.first_name === null || user.data?.first_name === undefined) {
      navigate("/auth/profile");
    }
  }

  const checkUserCompleteProfile = () => {
    if (
      user?.supertokens_userid === null ||
      user?.supertokens_userid === undefined
    ) {
      navigate("/auth/login");
    }
  };

  useEffect(() => {
    doesSessionExistS();
    getUserDeatils();
    checkUserCompleteProfile();
  }, []);
  const data1 = {
    title: "Credits",
    count: 2230,
    labels: ["Balance credits", "Used credits"],
    colors: ["#FDDF43", "#6CCBF2"],
    innerTitle: "Total",
    innerCount: 2357,
  };
  const data2 = {
    title: "User Status",
    count: 2000,
    labels: ["Active", "Inactive"],
    colors: ["#E028AD", "#6CCBF2"],
    innerTitle: "Total",
    innerCount: 2357,
  };
  return (
    <div>
      <motion.div className="default-padding">
        <div className="dashboarddiv">
          <div className="linecharts">
            <LineChart name="Api's Traffic" />
            <LineChart name="Credit Usage" />
          </div>
          <div className="dashboardbarcharts">
            <RingChart data={data1} />
            {/* <BarChart {data1} /> */}
            <RingChart data={data2} />
          </div>
        </div>
      </motion.div>
      <SideNavBar />
      <Outlet />
    </div>
  );
};

export default DashboardGraphs;

import React from "react";
import SideNavBar from "../components/SideNavBar";
import { Outlet } from "react-router-dom";
import "./../css/Dashboard.css";
const Dashboard = () => {
  return (
    <div>
      <SideNavBar />
      <Outlet />
    </div>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import SearchBar from "../components/SearchBar";
import UserBar from "../components/UserBar";
import "./../css/UserPage.css";
import RingChart from "../components/RingChart";
import BarChart from "../components/Barchart";
import NoUserBars from "../common/NoUserBar";
import NotDataMessage from "../common/NotDataMessage";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import apiHandler from "../api";
import { endpoint } from "../api/endpoint";

interface User {
  id: string;
  email: string;
  credits: string;
  supertokens_userid: string;
  is_active: boolean;
  // Add any other properties that a user might have
}

const UsersPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [userCount, setUserCount] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const user = useSelector((state: any) => state.user.userDetails);
  const navigate = useNavigate();

  const Ringdata = {
    title: "User Status",
    count: 2000,
    labels: ["Active", "Inactive"],
    colors: ["#E028AD", "#6CCBF2"],
    innerTitle: "Total",
    innerCount: 2357,
  };
  interface BarChartProps {
    data: {
      title: string;
      labels: string[];
      colors: string[];
      count: number[];
      legendtitle: string;
    };
    width: number;
    height: number;
  }

  const Bardata = {
    title: "Api's Requests",
    labels: [
      "endpoint1",
      "endpoint2",
      "endpoint3",
      "endpoint4",
      "endpoint5",
      "endpoint6",
      "endpoint7",
      "endpoint8",
    ],
    colors: ["#6CCBF2"],
    count: [200, 300, 400, 500, 600, 700, 800, 900],
    legendtitle: "Requests",
  };

  const getAllUsers = async () => {
    try {
      const res = await apiHandler({
        method: "GET",
        url: endpoint.GET_ALL_USERS + `?offset=${offset}&limit=10`,
      });

      if (res.data) {
        setIsLoading(false);
        setUsers([...users, ...res.data]);
        setUserCount(res.data.length);
        setOffset(offset + 10);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleLoadMore = () => {
    getAllUsers();
  };

  useEffect(() => {
    if (!user.is_superuser) {
      navigate("/users/" + user.supertokens_userid);
    }
    getAllUsers();
  }, []);

  useEffect(() => {
    if (users.length) {
      setIsLoading(false);
    }
  }, [users]);

  return (
    <div className="d-flex default-padding">
      {isLoading ? (
        <NoUserBars width="600px" />
      ) : (
        <>
          {users.length ? (
            <div className="users-left">
              <div className="users">
                {users.map((user, index) => (
                  <UserBar data={user} key={index} index={index} />
                ))}
              </div>
              {users.length < userCount && (
                <div className="load-more">
                  <h4 onClick={handleLoadMore}>Load More</h4>
                </div>
              )}
            </div>
          ) : (
            <div className="users-left">
              <NotDataMessage width={"600px"} message="No Users Found" />
            </div>
          )}
        </>
      )}
      <div className="users-right">
        <div className="sticky">
          <SearchBar className="searchInput" />
          <RingChart data={Ringdata} width="450px" height="380px" />
          <BarChart data={Bardata} width={450} height={380} />
        </div>
      </div>
    </div>
  );
};

export default UsersPage;

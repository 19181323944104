import "../css/Barchart.css";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { motion } from "framer-motion";
import React from 'react';

ChartJS.register(BarElement, CategoryScale, Tooltip, LinearScale, Legend);

interface BarChartProps {
    data: {
      title: string;
      labels: string[];
      colors: string[];
      count: number[];
      legendtitle: string;
    };
    width: number;
    height: number;
  }

const BarChart: React.FC<BarChartProps> = ({ height, width }) => {
  const data = {
    labels: [
      "endpoint1",
      "endpoint2",
      "endpoint3",
      "endpoint4",
      "endpoint5",
      "endpoint6",
      "endpoint7",
      "endpoint8",
    ].map((ele, i) => ele.slice(0, 3) + " " + i),
    datasets: [
      {
        data: [3, 10, 6, 8, 7, 9, 2, 4],
        backgroundColor: "#6CCBF2",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          color: "#e028ac",
          width: 3,
        },
        ticks: {
          color: "black", // color of the x axis lines
        },
      },
      y: {
        grid: {
          display: false,
        },
        offset: true,
        border: {
          color: "#e028ac",
          width: 3,
        },
      },
    },
  };

  return (
    <motion.div
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: 40, opacity: 0 }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="Barchart"
      style={{ height, width }}
    >
      <div className="heading">
        <p>Api's Requests</p>
      </div>
      <Bar data={data} options={options} />
    </motion.div>
  );
};

export default BarChart;

import { motion } from "framer-motion";
import React, { useState } from "react";
import { BiLogOut, BiSolidDashboard, BiSolidUser } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { NavLink } from "react-router-dom";
import AvatarPng from "../images/avatar.png";
import "./../css/SideNavBar.css";
import Session from "supertokens-web-js/recipe/session";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { resetUserDetails } from "../store/actions/userActions";

const SideNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state: any) => state.user.userDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleLogout = async (e: any) => {
    e.target.setAttribute("disabled", "true");
    await Session.signOut();
    dispatch(resetUserDetails());
    toast.success("Logged out successfully");
    e.target.removeAttribute("disabled");
    navigate("/auth/login");
  };
  return (
    <motion.div
      className="sidenavbar"
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      animate={isOpen ? "open" : "closed"}
      variants={{
        open: { width: "250px" },
        closed: { width: "80px" },
      }}
    >
      <div className="avatar-div">
        <div className="icon-wrapper">
          <img className="avatar-image" src={AvatarPng} alt="Avatar" />
        </div>
        {isOpen && <h4>Pragya</h4>}
      </div>
      <div className="navigations">
        <NavLink to="/" className="naviagation">
          <div className="icon">
            <BiSolidDashboard className="dashboard-icons" />
          </div>
          {isOpen && <h4>Dashboard</h4>}
        </NavLink>
        <NavLink to="/users" className="naviagation">
          <div className="icon">
            <BiSolidUser className="dashboard-icons" />
          </div>
          {isOpen && <h4>{user.is_superuser ? "Users" : "My Api Keys"}</h4>}
        </NavLink>
        <button onClick={handleLogout} className="naviagation">
          <div className="icon">
            <BiLogOut className="dashboard-icons" />
          </div>
          {isOpen && <h4>Logout</h4>}
        </button>
      </div>
      <NavLink
        to={`profile/${user?.supertokens_userid}`}
        className="profile naviagation"
      >
        <div className="icon">
          <CgProfile className="dashboard-icons" />
        </div>
        {isOpen && <h4>{user?.first_name + " " + user?.last_name}</h4>}
      </NavLink>
    </motion.div>
  );
};

export default SideNavBar;

import React, { useEffect, useRef, useState } from "react";
import WAVES from "vanta/dist/vanta.waves.min";
import Loginform from "../components/Loginform";

const LoginPage = () => {
  const [vantaEffect, setVantaEffect] = useState<any>(0);
  const myRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        WAVES({
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color: 0x227cb3,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <div className="loginpage">
      <div className="waves" ref={myRef}>
        <div className="title">
          <h1>
            <span>P</span>ragya
          </h1>
          <h3>Interview Api</h3>
        </div>
      </div>
      <div>
        <Loginform />
      </div>
    </div>
  );
};

export default LoginPage;

interface UserDetails {
  supertokens_userid: string;
  email: string;
}

export const saveUserDetails = (userDetails: UserDetails) => ({
  type: "SAVE_USER_DETAILS",
  payload: userDetails,
});

export const resetUserDetails = () => ({
  type: "RESET_USER_DETAILS",
});

import React from "react";
import "../css/VerificationLoader.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  consumeCode,
  clearLoginAttemptInfo,
} from "supertokens-web-js/recipe/passwordless";

const VerificationLoader = ({
  title,
  tagline,
  otherdevice = false,
}: {
  title: string;
  tagline: string;
  otherdevice?: boolean;
}) => {
  const navigate = useNavigate();

  async function handleLoginOnThisDevice() {
    try {
      let response = await consumeCode();
      console.log(response);

      if (response.status === "OK") {
        // we clear the login attempt info that was added when the createCode function
        // was called since the login was successful.
        await clearLoginAttemptInfo();
        if (
          response.createdNewRecipeUser &&
          response.user.loginMethods.length === 1
        ) {
          // user sign up success
          toast.success("User signed up successfully");
          navigate("/auth/profile");
        } else {
          // user sign in success
          toast.success("User signed in successfully");
          navigate("/");
        }
      } else {
        // this can happen if the magic link has expired or is invalid
        // or if it was denied due to security reasons in case of automatic account linking

        // we clear the login attempt info that was added when the createCode function
        // was called - so that if the user does a page reload, they will now see the
        // enter email / phone UI again.
        await clearLoginAttemptInfo();
        toast.error("Login failed. Please try again");
        // setIsSend(false);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }
  return (
    <div className="verify-page">
      <div className="cube">
        <div className="side"></div>
        <div className="side"></div>
        <div className="side"></div>
        <div className="side"></div>
        <div className="side"></div>
        <div className="side"></div>
      </div>
      <div className="up">
        <h1>{title}</h1>
        <p>{tagline}</p>
        {otherdevice && (
          <button onClick={handleLoginOnThisDevice} className="btt">
            Continue
          </button>
        )}
      </div>
      <div className="down">
        <h1>
          <span>P</span>ragya
        </h1>
      </div>
    </div>
  );
};

export default VerificationLoader;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  consumeCode,
  clearLoginAttemptInfo,
  getLoginAttemptInfo,
} from "supertokens-web-js/recipe/passwordless";
import { toast } from "react-hot-toast";
import VerificationLoader from "../common/VerificationLoader";
import { useDispatch } from "react-redux";
import { saveUserDetails } from "../store/actions/userActions";

const Auth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sameDevice, setSameDevice] = useState(false);

  async function isThisSameBrowserAndDevice() {
    return (await getLoginAttemptInfo()) !== undefined;
  }

  async function handleMagicLinkClicked() {
    const sameDevice = await isThisSameBrowserAndDevice();
    setSameDevice(sameDevice);
    console.log(sameDevice, "sameDevice");
    try {
      if (sameDevice) {
        let response = await consumeCode();
        console.log(response);

        if (response.status === "OK") {
          // we clear the login attempt info that was added when the createCode function
          // was called since the login was successful.
          dispatch(
            saveUserDetails({
              supertokens_userid: response.user.id,
              email: response.user.emails[0],
            })
          );
          await clearLoginAttemptInfo();
          if (
            response.createdNewRecipeUser &&
            response.user.loginMethods.length === 1
          ) {
            // user sign up success
            toast.success("User signed up successfully");
            navigate("/auth/profile");
          } else {
            // user sign in success
            toast.success("User signed in successfully");
            navigate("/");
          }
        } else {
          // this can happen if the magic link has expired or is invalid
          // or if it was denied due to security reasons in case of automatic account linking

          // we clear the login attempt info that was added when the createCode function
          // was called - so that if the user does a page reload, they will now see the
          // enter email / phone UI again.
          await clearLoginAttemptInfo();
          //console.log("Checking login failed");
          toast.error("Login failed. Please try again");
          // setIsSend(false);
        }
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  useEffect(() => {
    handleMagicLinkClicked();
  }, []);

  return (
    <div>
      {sameDevice ? (
        <VerificationLoader
          title={"Hold On!"}
          tagline={"You are verifying and redirecting to the dashboard."}
        />
      ) : (
        <VerificationLoader
          title={"Hold On!"}
          tagline={"Click here to login into this device."}
          otherdevice={true}
        />
      )}
    </div>
  );
};

export default Auth;

import React from "react";
import "./../css/NoDataCard.css";

const NoUserBars = ({ width = "auto" }) => {
  return (
    <div>
      <div className="noUserDataCard" style={{ width: width }}>
        <div className="rows"></div>
        <div className="rows"></div>
        <div className="rows"></div>
        <div className="rows"></div>
        <div className="rows"></div>
      </div>
    </div>
  );
};

export default NoUserBars;
import React, { useEffect, useState, useRef } from "react";
import { MdEmail, MdPin } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { FaGithub } from "react-icons/fa";
import { IoIosArrowRoundBack } from "react-icons/io";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import { createCode, resendCode, consumeCode, clearLoginAttemptInfo, getLoginAttemptInfo} from "supertokens-web-js/recipe/passwordless";

import {
  getAuthorisationURLWithQueryParamsAndSetState,
} from "supertokens-web-js/recipe/thirdparty";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { saveUserDetails } from "../store/actions/userActions";
import "./../css/LoginPage.css";

const Loginform = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [isSend, setIsSend] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isVerifyClicked, setIsVerifyClicked] = useState(false);
  const [isResendClicked, setIsResendClicked] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef(
    Array(6)
      .fill(null)
      .map(() => React.createRef())
  );
  const [cookies, setCookie] = useCookies(["email"]);
  const emailRef = useRef(null);

  // MagicLink and Otp

  // to send magic link to email for login or signup
  async function sendMagicLink(email: string) {
    try {
      let response = await createCode({
        email,
      });
      if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
        // this can happen due to automatic account linking. See that section in our docs.
        toast.error("Sign in not allowed.");
      } else {
        // Magic link sent successfully.
        toast.success("Please check your email for the magic link");
        const sendStatus = await hasInitialMagicLinkBeenSent();
        setIsSend(sendStatus);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you,
        // or if the input email / phone number is not valid.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  // to resend magic link to email for login or signup
  async function resendMagicLink() {
    setIsResendClicked(true);
    try {
      let response = await resendCode();

      if (response.status === "RESTART_FLOW_ERROR") {
        // this can happen if the user has already successfully logged in into
        // another device whilst also trying to login to this one.

        // we clear the login attempt info that was added when the createCode function
        // was called - so that if the user does a page reload, they will now see the
        // enter email / phone UI again.
        await clearLoginAttemptInfo();
        toast.error("Login failed. Please try again");
        setIsSend(false);
        setIsClicked(false);
      } else {
        // Magic link resent successfully.
        toast.success("Please check your email for the magic link");
        setIsVerifyClicked(false);
        setIsResendClicked(false);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
      setIsVerifyClicked(false);
      setIsResendClicked(false);
    }
  }

  // to check if magic link has been sent
  async function hasInitialMagicLinkBeenSent() {
    return (await getLoginAttemptInfo()) !== undefined;
  }

  // Event Handlers for Login and Signup
  const handleLoginOrSignup = async (e: any) => {
    setIsClicked(true);
    e.preventDefault();
    sendMagicLink(email);
  };

  // to change the input value of otp and its focus
  const handleInputChange = (index: number, value: string) => {
    setOtp([...otp.map((d, idx) => (idx === index ? value : d))]);
    if (value !== "") {
      if (index === 5) {
        return;
      }
      (inputRefs.current[index + 1].current as HTMLInputElement).focus();
    }
  };

  // to delete the input value of otp and its focus
  const handleKeyDown = (index: number, e: any) => {
    if (e.key === "Backspace" && index !== 0) {
      setOtp((prevOtp) => [
        ...prevOtp.map((d, idx) => (idx === index - 1 ? "" : d)),
      ]);

      // Use setTimeout to ensure that the state is updated before focusing
      setTimeout(() => {
        (inputRefs.current[index - 1].current as HTMLInputElement).focus();
      }, 0);
    }
  };

  // verify the otp
  async function handleOTPInput(otp: string) {
    try {
      let response = await consumeCode({
        userInputCode: otp,
      });

      if (response.status === "OK") {
        // we clear the login attempt info that was added when the createCode function
        // was called since the login was successful.
        dispatch(
          saveUserDetails({
            supertokens_userid: response.user.id,
            email: response.user.emails[0],
          })
        );
        await clearLoginAttemptInfo();
        if (
          response.createdNewRecipeUser &&
          response.user.loginMethods.length === 1
        ) {
          // user sign up success
          toast.success("sign up successful");
        } else {
          // user sign in success
          toast.success("sign in successful");
        }
        window.location.assign("/");
      } else if (response.status === "INCORRECT_USER_INPUT_CODE_ERROR") {
        // the user entered an invalid OTP
        toast.error(
          "Wrong OTP! Please try again. Number of attempts left: " +
            (response.maximumCodeInputAttempts -
              response.failedCodeInputAttemptCount)
        );
        setIsVerifyClicked(false);
      } else if (response.status === "EXPIRED_USER_INPUT_CODE_ERROR") {
        // it can come here if the entered OTP was correct, but has expired because
        // it was generated too long ago.
        toast.error(
          "Old OTP entered. Please regenerate a new one and try again"
        );
        setIsVerifyClicked(false);
      } else {
        // this can happen if the user tried an incorrect OTP too many times.
        // or if it was denied due to security reasons in case of automatic account linking

        // we clear the login attempt info that was added when the createCode function
        // was called - so that if the user does a page reload, they will now see the
        // enter email / phone UI again.
        await clearLoginAttemptInfo();
        toast.error("Login failed. Please try again");
        setIsSend(false);
        setIsClicked(false);
        setIsResendClicked(false);
        window.location.assign("/auth/login");
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  // Third Party Login for google and github
  async function handleThirdyPartyLoginClicked(domain: string) {
    try {
      const authUrl =
        await getAuthorisationURLWithQueryParamsAndSetState({
          thirdPartyId: domain,
          frontendRedirectURI:
            process.env.REACT_APP_WEBSITE_DOMAIN + "/auth/callback/" + domain,
        });
      window.location.assign(authUrl);
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  // to join the otp and call the handleOTPInput function
  const handleOtpInputCall = (e: any) => {
    setIsVerifyClicked(true);
    e.preventDefault();
    handleOTPInput(otp.join(""));
  };

  // to get the email from the input field and set it in the cookie
  const handleEmailChange = (e: any) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setCookie("email", newEmail, { path: "/" });
  };

  // to clear the input fields and set the email in the cookie
  const handleBack = async () => {
    setIsSend(false);
    setIsClicked(false);
    setEmail("");
    if (emailRef.current) {
      (emailRef.current as HTMLInputElement).disabled = false;
      (emailRef.current as HTMLInputElement).value = "";
    }
    // unset cookie
    setCookie("email", "", { path: "/" });

    await clearLoginAttemptInfo();
  };

  // to check if magic link has been sent
  useEffect(() => {
    const checkLoginAttempt = async () => {
      const sendStatus = await hasInitialMagicLinkBeenSent();
      setIsSend(sendStatus);
    };
    checkLoginAttempt();
  }, []);

  // to set the email in the input field and focus on the otp input field
  useEffect(() => {
    if (isSend) {
      if (emailRef.current) {
        (emailRef.current as HTMLInputElement).disabled = true;
        (emailRef.current as HTMLInputElement).value = cookies.email;
        (inputRefs.current[0].current as HTMLInputElement).focus();
      }
    }
  }, [isSend, cookies.email]);

  return (
    <>
      <motion.div
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4 }}
        className="one"
      >
        <div>
          <div className="heading">
            <h3>SignUp or Login</h3>
          </div>
          <hr />
          <div className="social-buttons">
            <div>
              <button
                onClick={() => {
                  handleThirdyPartyLoginClicked("google");
                }}
              >
                <div>
                  <i>
                    <FcGoogle />
                  </i>
                  Continue with Google
                </div>
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  handleThirdyPartyLoginClicked("github");
                }}
              >
                <div>
                  <i>
                    <FaGithub />
                  </i>
                  Continue with Github
                </div>
              </button>
            </div>
          </div>
          <div className="or-line">
            <hr />
            <p>or</p>
            <hr />
          </div>
          <form>
            <div>
              <div className="padd">
                <div className="email">
                  <div className="label-div">
                    <div>
                      <MdEmail style={{ fontSize: "20px" }} className="icon" />
                      <p className="em">Email</p>
                    </div>
                  </div>
                </div>
                <div>
                  <input
                    ref={emailRef}
                    id="email"
                    type="email"
                    placeholder="admin@gmail.com"
                    onChange={handleEmailChange}
                  />
                </div>
              </div>
            </div>
            <div>
              {isSend && (
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.2 }}
                  className="otp"
                >
                  <div className="label-div">
                    <div>
                      <MdPin style={{ fontSize: "20px" }} className="icon" />
                      <p className="em">OTP</p>
                    </div>
                  </div>
                  <div className="boxes">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        value={digit}
                        maxLength={1}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        ref={
                          inputRefs.current[
                            index
                          ] as React.RefObject<HTMLInputElement>
                        }
                      />
                    ))}
                  </div>
                </motion.div>
              )}
            </div>
            {!isSend ? (
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4 }}
              >
                <button
                  type="submit"
                  className="btt"
                  onClick={handleLoginOrSignup}
                  disabled={isClicked}
                >
                  Continue
                </button>
              </motion.div>
            ) : (
              <>
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.2 }}
                  className="two-buttons"
                >
                  <button
                    type="submit"
                    className="btt"
                    onClick={handleOtpInputCall}
                    disabled={isVerifyClicked}
                  >
                    Verify
                  </button>
                  <button
                    type="button"
                    className="btt"
                    onClick={resendMagicLink}
                    disabled={isResendClicked}
                  >
                    Resend
                  </button>
                </motion.div>
                <div className="back" onClick={handleBack}>
                  <i>
                    <IoIosArrowRoundBack />
                  </i>
                  <p>Back</p>
                </div>
              </>
            )}
          </form>
        </div>
      </motion.div>
    </>
  );
};

export default Loginform;

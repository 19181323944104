import React from "react";
import "../css/CreditsWarning.css";
export default function CustomBanner({
  alert,
  message,
  messagetag,
  buttontag,
}: {
  alert: boolean;
  message: string;
  messagetag: string;
  buttontag: string;
}) {
  return (
    <>
      {alert ? (
        <div className="warning red">
          <div>
            <h1>{message}</h1>
            <p>{messagetag}</p>
          </div>
          <div>
            <button className="btt">{buttontag}</button>
          </div>
        </div>
      ) : (
        <div className="warning green">
          <div>
            <h1>{message}</h1>
            <p>{messagetag}</p>
          </div>
          <div>
            <button className="btt">{buttontag}</button>
          </div>
        </div>
      )}
    </>
  );
}

import "./../css/Switch.css";
import React from "react";
interface SwitchProps {
  activeStatus?: boolean;
  handleFuc: () => void;
}

const Switch: React.FC<SwitchProps> = ({ activeStatus = false, handleFuc }) => {
  // const [switchOn, setSwitchOn] = useState(activeStatus);
  function handleSwitchOn() {
    handleFuc();
    // setSwitchOn((state) => !state);
  }
  return (
    <label className="switch">
      <input type="checkbox" checked={activeStatus} onChange={handleSwitchOn} />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;
